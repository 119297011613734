import { Path, Category } from "../../../types/types";

export const SET_CATEGORIES_PATHS = "SET_CATEGORIES_PATHS";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_SELECTED_SUBJECT = "SET_SELECTED_SUBJECT";

export interface SetCategoriesPaths {
  type: string;
  payload: {path: Path[]};
}

export interface SetCategories {
  type: string;
  payload: {categories: Category[]};
}

export interface SetSelectedCategory {
  type: string;
  payload: {category: Category};
}

export interface SetSelectedSubject {
  type: string;
  payload: {subject: Category};
}

export type CategoriesActions = SetCategoriesPaths | SetCategories | SetSelectedCategory | SetSelectedSubject
