import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useLoginStyles } from "./login.style";
import { LoginForm } from "../../components/LoginForm/loginForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Login = () => {
  const classes = useLoginStyles();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user
  })); 
  useEffect(()=>{
    if(userDetails.email){
      navigate("/");
    }
  }, [userDetails]);

  const {
    containerStyle,
    gridItemStyle,
    paperWrapperStyle
  } = classes;

  return !userDetails.email && (
    <Grid container className={containerStyle}>
      <Grid item xs={12} className={gridItemStyle}>
        <div className={paperWrapperStyle}>
          <LoginForm />
        </div>
      </Grid>
    </Grid>
  ) || (<div></div>);
};

export default Login;
