import axios from "axios";
import { toast } from "react-toastify";
import { Category } from "../types/types";

const PortalApis = {
  getCategories:(
  ) => {
    return (
      axios.get("api/categories/").then(
        (result: { data: any; }) => {
          return result && result.data;
        }
      ).catch(()=>{
        console.log("Something went wrong");
      })
    );
  },
  deleteCategory:(
    categoryId: number,
  ) => {
    return (
      axios.delete(`api/categories/${categoryId}/`).then(
        (result: { data: any; }) => {
          toast.success("Category Deleted Successfully", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          return result && result.data;
        }
      ).catch(()=>{
        toast.error("Category Was Not Deleted Successfully", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
    );
  },

  addNewCategory:(payload: Partial<Category>) :Promise<Category> =>  {
    return (
      axios.post("api/categories/", payload).then(
        result =>  {
          toast.success("Category Added Successfully", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          return result && result.data;
        }
      ).catch(()=>{
        toast.error("Unable To Add Category", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
    );
  },
  editCategory:(payload: Category) :Promise<Category> =>  {
    return (
      axios.put(`api/categories/${payload.id}/`, payload).then(
        result =>  {
          toast.success("Category Edited Successfully", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          return result && result.data;
        }
      ).catch(()=>{
        toast.error("Unable To Edit", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
    );
  },

  listCategoryLinks:(
    categoryId: string | undefined
  ) => {
    return (
      axios.get(`api/categories/${categoryId}/links/`).then(
        (result: { data: any; }) => {
          return result && result.data;
        }
      ).catch(()=>{
        console.log("Something went wrong");
      })
    );
  },

  listAllLinks:(
  ) => {
    return (
      axios.get("api/links/").then(
        (result: { data: any; }) => {
          return result && result.data;
        }
      ).catch(()=>{
        console.log("Something went wrong");
      })
    );
  },
  
  addCategoryLink:(
    title: string,
    content: string,
    icon: string,
    categoryId: string,
    file: Blob, 
    url: string,
    send_announcement: boolean,
  ) => {
    const data = new FormData();
    data.append("title", title);
    url && data.append("url", url);
    content && data.append("content", content);
    data.append("category_id", categoryId);
    data.append("icon", icon);
    data.append("send_announcement", JSON.stringify(send_announcement));
    file && data.append("file", file);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      timeout: 60 * 10 * 1000,
    };
    return (
      axios.post(`api/categories/${categoryId}/links/`, data, config).then(
        (result: { data: any; }) => {
          toast.success("Link Added Successfully", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          return result && result.data;
        }
      ).catch(()=>{
        toast.error("Link Was Not Added Successfully", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
    );
  },
  editCategoryLink:(
    linkId: number,
    title: string,
    content: string,
    icon: string,
    categoryId: string,
    file: Blob | string,
    url: string,
    send_announcement: boolean,
  ) => {
    const data = new FormData();
    data.append("title", title);
    url && data.append("url", url);
    content && data.append("content", content);
    data.append("category_id", categoryId);
    data.append("icon", icon);
    data.append("send_announcement", JSON.stringify(send_announcement));
    if (typeof file === "string") {
      file = `static${file.split("static")[1]}`;
    }
    file && data.append("file", file);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      timeout: 60 * 10 * 1000,
    };
    return (
      axios.put(`api/categories/${categoryId}/links/${linkId}/`, data, config).then(
        (result: { data: any; }) => {
          toast.success("Link Edited Successfully", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          return result && result.data;
        }
      ).catch(()=>{
        toast.error("Unable to Edit Link", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
    );
  },
  deleteLink:(
    categoryId: number,
    linkId: number
  ) => {
    return (
      axios.delete(`api/categories/${categoryId}/links/${linkId}/`).then(
        (result: { data: any; }) => {
          toast.success("Link Deleted Successfully", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          return result && result.data;
        }
      ).catch(()=>{
        toast.error("Unable To Delete Link", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      })
    );
  },
  getNews: () => {
    return axios
      .get("api/news/")
      .then((result: { data: any }) => {
        return result && result.data;
      })
      .catch(() => {
        console.log("Something went wrong");
      });
  },
  addNewsItem: async (
    title: string,
    content: string,
    image: Blob,
    deadline_date: string,
    send_announcement: boolean,
    summary: string
  ) => {
    const data = new FormData();
    data.append("title", title);
    data.append("content", content);
    data.append("summary", summary);
    image && data.append("image", image);
    data.append("deadline_date", deadline_date);
    data.append("send_announcement", JSON.stringify(send_announcement));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 60 * 10 * 1000,
    };
    try {
      const result = await axios
        .post("api/news/", data, config);
      toast.success("News Item Added Successfully", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return result && result.data;
    } catch {
      toast.error("News Item Was Not Added Successfully", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    }
  },
  editNewsItem: async (
    id: number,
    title: string,
    content: string,
    image: Blob | string,
    deadline_date: string,
    send_announcement: boolean,
    summary: string
  ) => {
    const data = new FormData();
    data.append("title", title);
    data.append("content", content);
    data.append("summary", summary);
    data.append("send_announcement", JSON.stringify(send_announcement));
    data.append("deadline_date", deadline_date);
    if (typeof image === "string") {
      image = `static${image.split("static")[1]}`;
    }
    image && data.append("image", image);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 60 * 10 * 1000,
    };
    try {
      const result = await axios
        .put(`api/news/${id}/`, data, config);
      toast.success("News Edited Successfully", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      return result && result.data;
    } catch {
      toast.error("Unable to Edit News Item", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    }
  },
  deleteNewsItem: (newsId: number, show = true) => {
    return axios
      .delete(`api/news/${newsId}/`)
      .then((result: { data: any }) => {
        if (show) {
          toast.success("News Deleted Successfully", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
        }
        return result && result.data;
      })
      .catch(() => {
        toast.error("Unable To Delete News", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        });
      });
  },
};
export default PortalApis;