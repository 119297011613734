import { matchPath } from "react-router";
import * as icons from "@mui/icons-material";
import { Category, Link } from "../types/types";

export const checkIfMatchPath = (routes: string, path: string) => {
  const isPathMatch = matchPath(routes, path);
  return isPathMatch;
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};

export const getUserObj = ()=> {
  return JSON.parse(localStorage.getItem("user") || "{}");
};

export const setUserDetails = (user: any)=> {
  return localStorage.setItem("user",  JSON.stringify(user));
};

const iconsOptions = Object.keys(icons).filter((option)=> option.includes("Outlined"));

export const handleIconInputChange = (e: any) => {
  const word = e.target.value;
  if(word.length <= 3) {
    return;
  }
  const matches = iconsOptions.filter((option)=> option.toLowerCase().includes(word.toLowerCase())).slice(0, 10);
  return matches;
};

export const trunclateLongText = (text: string, fromNews?: boolean) => {
  const maxCharLength = fromNews? 80: 150;
  if (text && text.length > maxCharLength) {
    return text.slice(0, maxCharLength) + "...";
  }
  return text;
};