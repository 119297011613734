import {AppBar, Box, MenuItem} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AsalLogo from "../../images/asal_logo_white.svg";
import {useUpperBarStyle} from "./UpperBar.style";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {setUserObj} from "../../redux/actions/userObject";
import {NonAuthRoutes} from "../../constants/routes/routes";

export const UpperBar = ({bgColor}: any) => {
  const classes = useUpperBarStyle();
  const navigate = useNavigate();
  const {appBar, asalLogo, appBarButton, asalLink} = classes;
  const location = useLocation();
  const isLoginPage = location.pathname === NonAuthRoutes.login;
  const {userDetails} = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
  }));
  const dispatch = useDispatch();

  const logOut = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(setUserObj(null));
    localStorage.removeItem("redirect_url");
    navigate(NonAuthRoutes.login);
  };

  return !isLoginPage ? (
    <Box>
      <AppBar className={appBar} position="absolute" color={bgColor || "transparent"}>
        <Link to="/" className={asalLink}>
          <img src={AsalLogo} className={asalLogo}/>
        </Link>
        <Box
          style={{
            display: "flex",
          }}
        >
          {userDetails.email && (
            <>
              {userDetails.is_superuser && <MenuItem
                component={Link}
                to="/settings"
                className={appBarButton}
              >
                <SettingsIcon color={"primary"}
                />
              </MenuItem>}
              <MenuItem className={appBarButton} onClick={() => logOut()}>
                <LogoutIcon
                  color={"primary"}
                />
              </MenuItem>
            </>
          )}
        </Box>
      </AppBar>
    </Box>
  ) : (
    <></>
  );
};
