// eslint-disable-next-line @typescript-eslint/no-var-requires
const icons = require("@mui/icons-material");
import stringSimilarity from "string-similarity";

const useIcons = (word: any) => {
  if(!word || (typeof word  === "object" && Object.keys(word).length === 0)) {
    return null;
  }
  const iconsNames = Object.keys(icons);

  const matches = stringSimilarity.findBestMatch(word, iconsNames);
  const bestMatch = matches.bestMatch.target;
  const Icon: any = icons[bestMatch as any];
  return Icon;
};

export default useIcons;