import { makeStyles } from "@mui/styles";

export const useSettingsStyles = makeStyles(() => ({
  root: {
    padding: 90,
    display: "flex",
    flexDirection: "column",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "white",
    },
  },
  categoriesBoxWrapper: {
    marginBottom: "20px",
  },
  boxHeader: {
    display: "flex",
    alignItems: "center",
  },
  widthAuto: {
    width: "auto !important",
  },
  width20: {
    width: "20%",
  },
  width25: {
    width: "25%",
  },
  width15: {
    width: "15%",
  },
  width10: {
    width: "10%",
  },
  width5: {
    width: "5%",
  },
}));
