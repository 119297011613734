import { makeStyles } from "@mui/styles";
import theme from "../../Theme";

export const useUpperBarStyle = makeStyles(() => ({
  appBar: {
    zIndex: 1,
    height: "60px",
    width: "100%",
    boxShadow: "none",
    "&.MuiPaper-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      boxShadow: "none",
    },
  },
  asalLogo: {
    height: "60%",
    [theme.breakpoints.down("md")]: {
      height: "40%",
    },    marginLeft: "20px",
  },
  appBarButton: {
    width: "60px",
    height: "60px",
  },
  asalLink:{
    display: "flex",
    alignItems: "center",
  }
}));
