export interface Category {
    id: number;
    name: string;
    created_at?: string;
    updated_at?: string;
    links?: any;
    description?: string;
    icon?: any;
}

export interface Link {
    id: number;
    title: string;
    url?: string;
    file?: string;
    icon: string;
    category: Category;
    created_at: string;
    updated_at: string;
    content?: string
    send_announcement: boolean;
}

export interface Path {
  name: string,
  id: number | null
  type: Path_Type.Category | Path_Type.Subject | Path_Type.Home
}

export enum Path_Type {
  Category = "Category",
  Subject = "Subject",
  Home = "Home"
}

export interface News {
  send_announcement: boolean;
  id: number;
  title: string;
  image?: string;
  deadline_date: string;
  created_at: string;
  updated_at: string;
  content: string;
  summary: string;
}
