import React, { useState } from "react";
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Autocomplete,
  debounce,
  AutocompleteRenderInputParams,
  Alert,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Category } from "../../types/types";
import { useCategoryFormDialogStyles } from "./useCategoryFormDialogStylesFormDialog.style";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MuiTextField from "@mui/material/TextField";
import * as Yup from "yup";
import PortalApis from "../../apis/PortalApi";
import useIcons from "../../hooks/useIcons";
import { handleIconInputChange } from "../../utils";

interface CategoryDialogProps {
  setOpen: (data: { category: Category | null; open: boolean }) => any;
  open: boolean;
  category: Category | null;
  setCategories: any;
  categories: Category[];
}

const CategoryFormDialog = (props: CategoryDialogProps) => {
  const { setOpen, open, category, setCategories, categories } = props;
  const [iconsOptions, setIconsOptions] = useState<string[]>([]);
  const [error, setError] = useState("");
  const classes = useCategoryFormDialogStyles();

  const checkCategoryUnique = (value: string) => {
    let unique = true;
    categories.forEach((category: Category) => {
      if (category.name === value) {
        unique = false;
      }
    });
    return unique;
  };

  const submitForm = (data: any) => {
    if (category && category.id) {
      PortalApis.editCategory({
        id: category.id,
        name: data.name,
        description: data.description,
        icon: data.icon,
      }).then((category) => {
        setCategories((oldCategories: Category[]) =>
          [...oldCategories.filter((b) => b.id !== category.id), category].sort(
            (a: Category, b: Category) => a.id - b.id
          )
        );
        setOpen({ open: false, category: null });
      });
    } else {
      if (checkCategoryUnique(data.name)) {
        PortalApis.addNewCategory({
          name: data.name,
          description: data.description,
          icon: data.icon,
        }).then((category: Category) => {
          setCategories((oldCategories: Category[]) => [
            ...oldCategories,
            category,
          ]);
          setOpen({ open: false, category: null });
        });
      } else {
        setError("Category Name is Duplicated !");
      }
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().nullable().required("Required"),
    icon: Yup.string().nullable().required("Required"),
  });

  const handleIconChange = (e: any) => {
    const iconsOptions = handleIconInputChange(e);
    iconsOptions && setIconsOptions(iconsOptions);
  };

  return (
    (open && (
      <Dialog
        open={open}
        onClose={() => setOpen({ open: false, category: null })}
      >
        <DialogTitle>
          {category ? `Editing Category: ${category.name}` : "Add New Category"}
        </DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <Formik
            initialValues={{
              name: category && category.name,
              description: category && category.description,
              icon: category && category.icon,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, formikHelpers) => {
              submitForm(values);
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Form>
                  <Box margin={2} flexDirection={"column"}>
                    <Field
                      component={MuiTextField}
                      defaultValue={category && category.name}
                      label="Name"
                      name={"name"}
                      error={(touched["name"] && !!errors["name"]) || false}
                      onChange={(e: any) =>
                        setFieldValue("name", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="name"
                      render={(msg: string) => <div>{msg}</div>}
                    />
                  </Box>
                  <Box margin={2} flexDirection={"column"}>
                    <Field
                      component={MuiTextField}
                      defaultValue={category && category.description}
                      label="Description"
                      name={"description"}
                      multiline
                      fullWidth
                      maxRows={10}
                      error={
                        (touched["description"] && !!errors["description"]) ||
                        false
                      }
                      onChange={(e: any) =>
                        setFieldValue("description", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="description"
                      render={(msg: string) => <div>{msg}</div>}
                    />
                  </Box>
                  <Box margin={2} flexDirection={"column"}>
                    <Field
                      name="icon"
                      key={"icon"}
                      component={Autocomplete}
                      defaultValue={category && category.icon}
                      multiple={false}
                      options={iconsOptions}
                      clearOnBlur={false}
                      onChange={(e: any, icon: any): any => {
                        setFieldValue("icon", icon);
                      }}
                      getOptionLabel={(option: any) => `${option}`}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <MuiTextField
                          {...params}
                          onChange={debounce(handleIconChange, 500)}
                          error={(touched["icon"] && !!errors["icon"]) || false}
                          name="icon"
                          label="Icon"
                          variant="outlined"
                        />
                      )}
                      renderOption={(props: any, option: any) => {
                        const Icon = useIcons(option);
                        return (
                          <li {...props}>
                            <Icon fontSize="large" />{" "}
                            {option.replace("Outlined", "")}
                          </li>
                        );
                      }}
                    />
                    <ErrorMessage name="icon" />
                  </Box>
                  <Button type="submit" fullWidth variant="contained">
                    Submit
                  </Button>
                </Form>
              </LocalizationProvider>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    )) || <div></div>
  );
};

export default CategoryFormDialog;
