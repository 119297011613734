import { makeStyles } from "@mui/styles";
import theme from "../../Theme";

export const useCategoriesNavigatorStyle = makeStyles(() => ({
  Navigator: {
    display: "flex",
    alignItems: "center",
    width: "82vw",
    margin: "20px auto",
    [theme.breakpoints.up("lg")]: {
      width: "72vw",
    }
  },
  button: {
    display: "flex",
    alignItems: "center",
  }
}));