import axios from "axios";
import { toast } from "react-toastify";

const errorHandler = (error) => {
  if (error.response.status >= 400 ) {
    if(error.response.status === 403){
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      window.location.href="/login";
      return;
    }
    const message = typeof error?.response?.data === "string" ? error?.response?.data : "Something went wrong";
    toast.error(message, {
      position: "top-center",
      autoClose: true,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      onClose: () => {
        toast.dismiss();
      }
    });
  }

  return Promise.reject({ ...error });
};

const AxiosInterceptor = () => {
  // Add a request interceptor
  axios.interceptors.request.use(
    config => {
      // eslint-disable-next-line no-undef
      config.baseURL = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem("access_token");
      if (token) {
        config.headers["Authorization"] = "Token " + token;
        config.headers["Content-Type"] = "application/json";
      }
      return config;
    },
    error => {
      errorHandler(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {return response;},
    (error) => errorHandler(error)
  );
  return(
    <div>
     
    </div>);
};
export default AxiosInterceptor;