import { makeStyles } from "@mui/styles";
import theme from "../../Theme";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useCategoriesStyles = makeStyles(() => ({
  homeWrapper: {
    width: "90vw",
    margin: "20px auto 40px",
    paddingBottom:"60px",
    [theme.breakpoints.up("sm")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70vw",
    },
  },
  root: {
    display: "grid",
    columnGap: "18px",
    rowGap: "20px",
    gridTemplateColumns: "auto",
    [theme.breakpoints.up("sm")]: {
      rowGap: "30px",
      gridTemplateColumns: "repeat(2, 1fr)",
      columnGap: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "70vw",
    }
  },
  categoryCard: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    padding: "20px 20px",
    borderRadius: "2px",
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: "pointer",
    backgroundColor: "#fff",
    "&:hover": {
      border: `1px solid ${theme.palette.secondary.main}`,
    }
  },
  badge:{
    zIndex: 0,
    position: "absolute !important",
    width: "-webkit-fill-available !important",
    top: "0px",
    right: "55px",
  },
  name: {
    color: `${theme.palette.text.primary}`,
    fontSize: "20px !important",
    fontWeight: "700 !important",
  },
  icon: {
    marginRight: "20px"
  },
  desc: {
    color: `${theme.palette.text.secondary}`,
    marginTop: "8px",
    fontSize: "15px !important"
  },
  categoryContent: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "30px",
    rowGap: "30px",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    }
  },
  linkCard: {
    flexDirection: "column",
    position: "relative",
    "& a": {
      color: `${theme.palette.secondary.main}`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textDecoration: "none"
    },
    "& .title": {
      fontWeight: "500",
      marginTop: "10px",
      textAlign: "center",
    }
  }
}));