import { Box, Button } from "@mui/material";
import { useCategoriesNavigatorStyle } from "./categoriesNavigator.style";
import { NonAuthRoutes } from "../../constants/routes/routes";
import { useNavigate } from "react-router-dom";
import { Category, Path, Path_Type } from "../../types/types";

interface ParentProps {
  paths: Path[];
  onCategoryClick: (category: Category | null, categoryId?: number) => void;
}

const CategoriesNavigator = ({ paths, onCategoryClick }: ParentProps) => {
  const classes = useCategoriesNavigatorStyle();
  const history = useNavigate();

  const onLinkClick = (path: Path) => {

    if (path.type === Path_Type.Home) {
      onCategoryClick(null);
      history("/");
    }
    if (path.type === Path_Type.Category) {
      path.id && onCategoryClick(null, path.id);
      history(`../category/${path.id}`, { replace: true });
    }
  };

  return (
    <Box className={classes.Navigator}>
      {
        paths?.map((path, index) => {
          return (
            <div className={classes.button} key={`path-${path.name}-${path.id}`}>
              <Button onClick={() => onLinkClick(path)}>
                {path.name}
              </Button>
              {
                (index !== paths.length - 1) && (
                  <div>\</div>
                )
              }
            </div>
          );
        })
      }
    </Box>
  );
};

export default CategoriesNavigator;
