import {Navigate, useLocation, useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { NonAuthRoutes } from "../../constants/routes/routes";
import {useEffect} from "react";

export const SecuredRoute = ({
  children,
}: any) => {
  const { userDetails } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
  }));
  const location = useLocation();

  if (!userDetails?.is_active) {
    const shouldHaveRedirect = location.pathname !== "/" || location.search;
    shouldHaveRedirect && localStorage.setItem("redirect_url", location.pathname + location.search);
    return <Navigate to={NonAuthRoutes.login} replace />;
  }

  return children;
};

export default SecuredRoute;