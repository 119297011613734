import { makeStyles } from "@mui/styles";
import theme from "../../Theme";

export const useSearchStyle = makeStyles(() => ({
  searchPaper:{
    borderRadius: "2px !important",
    width: "250px",
    height: "45px",
    [theme.breakpoints.up("sm")]: {
      width: "450px",
    },
  },
  inputText: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      opacity: 0
    },
    "& .css-h9fz1k-MuiFormLabel-root-MuiInputLabel-root": {
      opacity: 0
    }
  }
}));