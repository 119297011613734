export const SET_USER_OBJ = "SET_USER_OBJ";
export const CLEAR_USER_OBJ = "CLEAR_USER_OBJ";


export interface UserObject {
  id: number,
  last_login: string,
  type: string,
  is_superuser: boolean,
  email: string,
  username: string,
  first_name: string,
  is_staff: boolean,
}

export interface AuthObject {
  user: UserObject;
}

export interface SetUserObj {
  type: typeof SET_USER_OBJ;
  payload: AuthObject;
}

export interface ClearUserObj {
  type: typeof CLEAR_USER_OBJ;
  payload: any;
}



export type UserObjActions =
  | SetUserObj
  | ClearUserObj
export type UserObjectType = UserObject;
