enum ProtectedAuthRoutes {
  settings = "/settings",
}

enum AuthRoutes {
  root = "/",
  category = "category/:categoryId",
  subject = "category/:categoryId/subject/:subjectId"
}


enum NonAuthRoutes {
  login = "/login",
}

export {
  ProtectedAuthRoutes,
  AuthRoutes,
  NonAuthRoutes
};