import { Box, Typography } from "@mui/material";
import { useHeaderStyle } from "./Header.style";
import SearchBox from "../SearchBox/SearchBox";
import {UpperBar} from "../UpperBar/UpperBar";

const Header = () => {
  const classes = useHeaderStyle();
  const { boxStyle } = classes;
  return (
    <>
      <Box className={boxStyle}>
        <UpperBar/>
        <SearchBox />
      </Box>
    </>
  );
};
export default Header;
