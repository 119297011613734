import { makeStyles } from "@mui/styles";
import theme from "../../Theme";

export const ContentStyles = makeStyles(() => ({
  content: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "20px",
    color: `${theme.palette.text.primary}`,
    lineHeight: "1.7"
  },
}));