import { makeStyles } from "@mui/styles";

export const useLoginFormStyles = makeStyles(() => ({
  loginFormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  asalLogo: {
    width: 300,
    height: 70,
    margin: 50,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "70px !important",
  },
  forgetPasswordButton: {
    fontFamily: "RobotoRegular",
    textTransform: "none",
    color: "#0880AE",
    fontSize: 16,
    marginBottom: 28,
  },
  labelTextStyle: {
    fontSize: "16px",
    letterSpacing: "0",
    lineHeight: "21px",
    marginBottom: 8,
    fontFamily: "RobotoRegular",
  },
  inputFieldStyle: {
    background: "#F6F7F8",
    marginTop: 0,
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0px 1000px white inset",
    },
  },
  inputFieldHighlightedStyle: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: 1,
        boxShadow: "0 1px 8px 0 rgba(0,148,141,0.23)",
      },
      "&:hover fieldset": {
        boxShadow: "0 1px 8px 0 rgba(0,148,141,0.23)",
      },
      "&.Mui-focused fieldset": {
        boxShadow: "0 1px 8px 0 rgba(0,148,141,0.23)",
      },
    },
  },
  inputFieldErroredStyle: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: 1,
        boxShadow: "0 1px 8px 0 rgba(0,148,141,0.23)",
      },
      "&:hover fieldset": {
        boxShadow: "0 1px 8px 0 rgba(0,148,141,0.23)",
      },
      "&.Mui-focused fieldset": {
        boxShadow: "0 1px 8px 0 rgba(0,148,141,0.23)",
      },
    },
  },

  formControlStyle: {
    width: "100%",
    "& .MuiFormControl-root": {
      backgroundColor: "transparent",
    },
  },
}));
