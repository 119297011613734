import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Category from "../../components/Category/Category";
import Header from "../../components/Header/Header";
import { Categories } from "../Categories/Categories";
import CategoriesNavigator from "../../components/CategoriesNavigator/CategoriesNavigator";
import {
  Category as CategoryType,
  Link,
  News,
  Path,
  Path_Type,
} from "../../types/types";
import PortalApis from "../../apis/PortalApi";
import useIcons from "../../hooks/useIcons";
import { categoriesNavigatorInitialPath as initialPath } from "../../constants";
import { useCategoriesStyles } from "../Categories/categories.style";
import SubjectContent from "../../components/SubjectContent/SubjectContent";
import { useDispatch, useSelector } from "react-redux";
import { setCategories as setCategoriesAction } from "../../redux/actions/categories/actions";
import { RootState } from "../../redux/store";
import { Ticker } from "../../components/NewsTicker/Ticker";
import {Backdrop, CircularProgress} from "@mui/material";
const Home = () => {
  const classes = useCategoriesStyles();
  const { categoryId: defaultCategoryId, subjectId: defaultSubjectId } =
    getParams();
  const [paths, setPaths] = useState<Path[]>([initialPath]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryType>();
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [news, setNews] = useState<News[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<Link>();
  const [loading, setLoading] = useState(true);
  const searchSelectedCategory = useSelector(
    (state: RootState) => state.categories.selectedCategory
  );
  const searchSelectedSubject = useSelector(
    (state: RootState) => state.categories.selectedSubject
  );
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
  }));
  const currentDate = new Date();

  function getParams() {
    const { categoryId, subjectId } = useParams();

    return {
      categoryId: categoryId ? parseInt(categoryId) : null,
      subjectId: subjectId ? parseInt(subjectId) : null,
    };
  }

  if (!defaultCategoryId && !defaultSubjectId) {
    if (selectedCategory) {
      setSelectedCategory(undefined);
      setSelectedSubject(undefined);
      setPaths([initialPath]);
    }
  }

  const handleGetCategories = async () => {
    const categories = await PortalApis.getCategories().then(
      (categories) => categories
    );
    if (categories) {
      setIcons(categories);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(setCategoriesAction(categories));
    if (defaultCategoryId) {
      const category = getCategoryById(categories, defaultCategoryId);
      if (category) {
        setPaths((paths) => [
          ...paths,
          {
            name: category.name,
            id: defaultCategoryId,
            type: Path_Type.Category,
          },
        ]);
        setSelectedCategory(category);
        if (defaultSubjectId) {
          const subject = getSubjectById(category, defaultSubjectId);
          setSelectedSubject(subject);
          setPaths((paths) => [
            ...paths,
            {
              name: subject.title,
              id: defaultSubjectId,
              type: Path_Type.Subject,
            },
          ]);
        }
      }
    } else {
      setSelectedCategory(undefined);
    }
  };

  const handleGetNews = async () => {
    const news = await PortalApis.getNews().then((news) => news);
    if (news) {
      setNews(news);
    }
    handleDeadline(news);
  };

  const handleDeadline = async (news: News[]) => {
    news?.forEach((newsItem: News) => {
      const date = new Date(newsItem.deadline_date);
      const checkDate = date <= currentDate;
      if (checkDate) {
        if (userDetails.email && userDetails.is_superuser) {
          PortalApis.deleteNewsItem(newsItem.id, false);
        }
        setNews(news.filter((news: News) => news.id != newsItem.id));
      }
    });
  };

  const setIcons = (categories: CategoryType[]) => {
    const categoriesWithIcons = categories?.map((category: CategoryType) => {
      const Icon = useIcons(category.icon);
      return { ...category, icon: Icon };
    });

    setCategories(categoriesWithIcons);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect( () => {
    setLoading(true);
    Promise.all([handleGetCategories(), handleGetNews()]).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (searchSelectedCategory && searchSelectedSubject) {
      setSelectedCategory(searchSelectedCategory);
      setSelectedSubject(searchSelectedSubject);
      setPaths([
        initialPath,
        {
          name: searchSelectedCategory.name,
          id: searchSelectedCategory.id,
          type: Path_Type.Category,
        },
        {
          name: searchSelectedSubject.title,
          id: searchSelectedSubject.id,
          type: Path_Type.Subject,
        },
      ]);
    }
  }, [searchSelectedCategory, searchSelectedSubject]);

  const getCategoryById = (categories: CategoryType[], id: number) => {
    return categories?.find((category) => category.id === id);
  };

  const getSubjectById = (category: CategoryType, id: number) => {
    return category?.links?.find((link: Link) => link.id === id);
  };

  const onCategoryClick = (category: CategoryType) => {
    updateCategory(category);
  };

  const updateCategory = (
    category: CategoryType | null,
    categoryId?: number
  ) => {
    if (category) {
      const { id, name } = category;
      setPaths((paths) => [...paths, { name, id, type: Path_Type.Category }]);
      setSelectedCategory(category);
    } else if (categoryId) {
      const category = getCategoryById(categories, categoryId);
      const filteredPaths = filterPaths();
      setPaths(filteredPaths);
      setSelectedSubject(undefined);
      setSelectedCategory(category);
    } else {
      setPaths([initialPath]);
      setSelectedSubject(undefined);
      setSelectedCategory(undefined);
    }
  };

  const filterPaths = () => {
    return paths?.filter((path) => path.type !== Path_Type.Subject);
  };

  const onSubjectClick = (subject: Link) => {
    if (subject) {
      const { id, title } = subject;
      setPaths((paths) => [
        ...paths,
        { name: title, id, type: Path_Type.Subject },
      ]);
      setSelectedSubject(subject);
    }
  };
  const Loader = () => (
    <Backdrop open>
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return (
    !loading ? <>
      <Header />
      {selectedCategory && (
        <CategoriesNavigator paths={paths} onCategoryClick={updateCategory} />
      )}
      <div className={classes.homeWrapper}>
        {selectedCategory && selectedSubject ? (
          <SubjectContent selectedSubject={selectedSubject} />
        ) : selectedCategory?.id ? (
          <Category
            category={selectedCategory}
            onSubjectClick={onSubjectClick}
          />
        ) : (
          <Categories
            categories={categories}
            onCategoryClick={onCategoryClick}
          />
        )}
      </div>
      {news.length > 0 && <Ticker newsItems={news} />}
    </> : <Loader/>
  );
};

export default Home;
