import { AuthAction, CLEAR_USER_OBJ, SET_USER_OBJ, User } from "../../redux/actions/userObject";
import {UserObject} from "../actions/userObject/types";
import {getUserObj} from "../../utils";
const localStorageUser: UserObject = getUserObj();

const initialState: User | undefined = {
  user: localStorageUser || {}
};

const userObjReducer = (state = initialState, action: AuthAction): any => {
  switch (action.type) {
  case SET_USER_OBJ:
    return { ...state, user: { ...action.payload.user } };
  case CLEAR_USER_OBJ:
    return { ...state, user: { ...initialState.user } };
  default:
    return { ...state };
  }
};

export { initialState, userObjReducer };
