import React from "react";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";


const ConfirmationDialog = (props: any) => {
  const { title, children, open, setOpen, onConfirm, setWarning } = props;
  return (
    <Dialog
      open={open}
      onClose={() => {setWarning(""); setOpen({ open:false, data:null });}}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color={"error"}
          onClick={() => {setWarning(""); setOpen({ open:false, data:null });}}
        >
          No
        </Button>
        <Button
          variant="contained"
          color={"error"}
          onClick={() => {
            setWarning("");
            setOpen({ open:false, data:null });
            onConfirm();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;