
import {Badge, Box} from "@mui/material";
import { Category as CategoryType, Link } from "../../types/types";
import { useCategoriesStyles } from "../../containers/Categories/categories.style";
import useIcons from "../../hooks/useIcons";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface ParentProps {
  category?: CategoryType
  onSubjectClick: (subject: Link) => void
}

const Category = (props: ParentProps) => {
  const classes = useCategoriesStyles();
  const navigate = useNavigate();

  const onSubjectClick = (subject: Link) => {
    if ((subject.file || subject.url) && !subject.content) {
      return;
    }
    navigate(`../category/${props.category?.id}/subject/${subject.id}`, { replace: true });
    props.onSubjectClick(subject);
  };

  if (!props.category?.links || props.category?.links?.length === 0) {
    return (
      <div>There is no Links related to this Category</div>
    );
  }

  const isNewlyAdded = (createdAt: string) => {
    const sevenDaysAgo = moment().subtract(7, "days");

    return moment(createdAt).isSameOrAfter(sevenDaysAgo, "day");
  };

  const isNewlyUpdated = (updatedAt: string) => {
    const sevenDaysAgo = moment().subtract(7, "days");

    return moment(updatedAt).isSameOrAfter(sevenDaysAgo, "day");
  };

  return (
    <Box className={classes.categoryContent}>
      {props?.category?.links && props.category.links.map((subject: Link) => {
        const Icon = useIcons(subject.icon);
        const link = subject.file || subject.url;
        const shouldShowTheBadge = isNewlyAdded(subject.created_at) || isNewlyUpdated(subject.updated_at);
        const badgeContent = isNewlyAdded(subject.created_at) ? "Newly Added" : isNewlyUpdated(subject.updated_at)? "Newly Updated" : "";
        const badgeColor = isNewlyAdded(subject.created_at)? "success" : isNewlyUpdated(subject.updated_at)? "warning" : undefined;

        return (
          <Box className={`${classes.categoryCard} ${classes.linkCard}`} key={subject.id} onClick={() => onSubjectClick(subject)}>
            {
              link ? (
                <>
                  <Badge className={classes.badge} badgeContent={badgeContent} color={badgeColor} invisible={!shouldShowTheBadge}></Badge>
                  <a href={link} target="_blank">
                    <Icon fontSize="large" />
                    <div className="title">{subject.title}</div>
                  </a>
                </>
              ) : (
                <>
                  <Icon fontSize="large" />
                  <div className="title">{subject.title}</div>
                </>
              )
            }
          </Box>
        );
      })}
    </Box>
  );
};

export default Category;