import React, { useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  debounce,
  DialogContent,
  DialogTitle,
  Alert,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Category, Link } from "../../types/types";
import { useLinkFormDialogStyles } from "./LinkFormDialog.style";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikValues,
} from "formik";
import MuiTextField from "@mui/material/TextField";
import * as Yup from "yup";
import PortalApis from "../../apis/PortalApi";
import useIcons from "../../hooks/useIcons";

import { handleIconInputChange } from "../../utils";

interface LinkDialogProps {
  setOpen: (data: { link: Link | null; open: boolean }) => any;
  open: boolean;
  link: Link | null;
  links: Link[];
  categories: Category[] | [];
  setLinks: any;
}
// eslint-disable-next-line @typescript-eslint/no-var-requires

const LinkFormDialog = (props: LinkDialogProps) => {
  const { setOpen, open, link, setLinks, categories, links } = props;
  const [iconsOptions, setIconsOptions] = useState<string[]>([]);
  const [error, setError] = useState("");
  const classes = useLinkFormDialogStyles();

  const checkLinkUnique = (value: string) => {
    let unique = true;
    links.forEach((link: Link) => {
      if (link.title === value) {
        unique = false;
      }
    });
    return unique;
  };

  const submitForm = (data: any) => {
    if (data?.file || data?.content || data?.url) {
      if (link && link.id) {
        PortalApis.editCategoryLink(
          link.id,
          data.title,
          data.content,
          data.icon,
          data.category.id,
          data.file,
          data.url,
          data.send_announcement
        ).then((link: Link) => {
          setLinks((oldLinks: Link[]) => [
            ...oldLinks.filter((fl) => fl.id != link.id),
            link,
          ]);
          setOpen({ open: false, link: null });
        });
      } else {
        if (checkLinkUnique(data.title)) {
          PortalApis.addCategoryLink(
            data.title,
            data.content,
            data.icon,
            data.category.id,
            data.file,
            data.url,
            data.send_announcement
          ).then((link: Link) => {
            setLinks((oldLinks: Link[]) => [...oldLinks, link]);
            setOpen({ open: false, link: null });
          });
        } else {
          setError("Link Title is Duplicated !");
        }
      }
    } else {
      setError("Link should have Content or attached File or page URL!");
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().nullable().required("Required"),
    category: Yup.mixed().nullable().required("Required"),
    icon: Yup.string().nullable().required("Required"),
  });

  const handleIconChange = (e: any) => {
    const iconsOptions = handleIconInputChange(e);
    iconsOptions && setIconsOptions(iconsOptions);
  };

  return (
    (open && (
      <Dialog open={open} onClose={() => setOpen({ open: false, link: null })}>
        <DialogTitle>
          {link ? `Editing Link: ${link.title}` : "Add New Link"}
        </DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <Formik
            initialValues={{
              title: link && link.title,
              content: link && link.content,
              file: link && link.file,
              url: link && link.url,
              icon: link && link.icon,
              category: link && link.category,
              send_announcement: link && link.send_announcement || false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, formikHelpers: FormikHelpers<FormikValues>) => {
              submitForm(values);
              formikHelpers.setSubmitting(false);
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Form>
                  <Box
                    margin={2}
                    alignContent={"center"}
                    flexDirection={"column"}
                  >
                    <Field
                      component={MuiTextField}
                      fullWidth
                      defaultValue={link && link.title}
                      label="Title"
                      name={"title"}
                      error={(touched["title"] && !!errors["title"]) || false}
                      onChange={(e: any) =>
                        setFieldValue("title", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="title"
                      render={(msg: string) => <div>{msg}</div>}
                    />
                  </Box>
                  <Box
                    margin={2}
                    alignContent={"center"}
                    flexDirection={"column"}
                  >
                    <Field
                      component={MuiTextField}
                      fullWidth
                      defaultValue={link && link.url}
                      label="URL"
                      name={"url"}
                      error={(touched["url"] && !!errors["url"]) || false}
                      onChange={(e: any) =>
                        setFieldValue("url", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="url"
                      render={(msg: string) => <div>{msg}</div>}
                    />
                  </Box>
                  <Box
                    margin={2}
                    alignContent={"center"}
                    flexDirection={"column"}
                  >
                    <Field
                      multiline
                      fullWidth
                      maxRows={10}
                      component={MuiTextField}
                      defaultValue={link && link.content}
                      label="Content"
                      name={"content"}
                      error={
                        (touched["content"] && !!errors["content"]) || false
                      }
                      onChange={(e: any) =>
                        setFieldValue("content", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name="content"
                      render={(msg: string) => <div>{msg}</div>}
                    />
                  </Box>
                  <Box
                    margin={2}
                    alignContent={"center"}
                    flexDirection={"column"}
                  >
                    <Field
                      type={"file"}
                      label="File"
                      name={"file"}
                      id={"file"}
                      value={undefined}
                      onChange={(e: any) => {
                        // eslint-disable-next-line no-debugger
                        setFieldValue("file", e.currentTarget.files[0]);
                      }}
                    />
                    <ErrorMessage
                      name="file"
                      render={(msg: string) => <div>{msg}</div>}
                    />
                  </Box>
                  <Box margin={2}>
                    <Field
                      name="icon"
                      key={"icon"}
                      component={Autocomplete}
                      defaultValue={link && link.icon}
                      multiple={false}
                      options={iconsOptions}
                      clearOnBlur={false}
                      onChange={(e: any, icon: any): any => {
                        setFieldValue("icon", icon);
                      }}
                      getOptionLabel={(option: any) => `${option}`}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <MuiTextField
                          {...params}
                          onChange={debounce(handleIconChange, 500)}
                          error={(touched["icon"] && !!errors["icon"]) || false}
                          name="icon"
                          label="Icon"
                          variant="outlined"
                        />
                      )}
                      renderOption={(props: any, option: any) => {
                        const Icon = useIcons(option);
                        return (
                          <li {...props}>
                            <Icon fontSize="large" />{" "}
                            {option.replace("Outlined", "")}
                          </li>
                        );
                      }}
                    />
                    <ErrorMessage name="icon" />
                  </Box>
                  {categories && categories.length > 0 && (
                    <Box margin={2}>
                      <Field
                        name="category"
                        key={"category"}
                        component={Autocomplete}
                        defaultValue={link?.category}
                        multiple={false}
                        options={categories}
                        clearOnBlur={false}
                        onChange={(event: any, category: any): any => {
                          values.category = category;
                        }}
                        getOptionLabel={(option: any) => `${option.name || ""}`}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <MuiTextField
                            {...params}
                            error={
                              (touched["category"] && !!errors["category"]) ||
                              false
                            }
                            name="category"
                            label="Category"
                            variant="outlined"
                          />
                        )}
                      />
                      <ErrorMessage name="category" />
                    </Box>
                  )}
                  <Box
                    margin={2}
                    alignContent={"center"}
                    flexDirection={"column"}
                  >
                    <label>
                      <Field type="checkbox" defaultValue={false} name="send_announcement"/>
                      Send Announcement To Office Email
                    </label>
                  </Box>
                  <Button type="submit" fullWidth variant="contained">
                    Submit
                  </Button>
                </Form>
              </LocalizationProvider>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    )) || <div></div>
  );
};

export default LinkFormDialog;
