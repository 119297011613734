import React, { useState } from "react";

import { useFormikContext } from "formik";
import { EnhacnedIconButton, useFormikTextFieldStyle } from "./formikTextfield.style";
import { FormControl, FormHelperText, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const FormikTextField = (Props: any): any => {
  const {
    setFieldValue,
    setFieldTouched,
    name,
    label,
    value,
    error,
    className,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleCallBack = () => {},
    container,
    FormControlStyle,
    type,
    isWithTwoLines,
    ...props
  } = Props;
  const classes = useFormikTextFieldStyle();
  const formik = useFormikContext();
  const handleChange = (e: any): void => {
    if (e.target.value.indexOf("\n") > -1) {
      formik.submitForm();
    } else {
      setFieldValue(name, e.target.value);
      setFieldTouched(name, true, false);
    }
  };

  const rowsNumber = isWithTwoLines ? 2 : 1;

  return (
    <FormControl
      error={!!error}
      className={FormControlStyle}
    >
      <TextField
        className={className}
        fullWidth
        id="outlined-basic"
        variant="standard"
        label={label}
        value={value}
        onChange={handleChange}
        type={type}
        multiline={isWithTwoLines}
        rows={rowsNumber}
        {...props}
      />
      {error && (
        <FormHelperText className={classes.FormHelperText} error={true}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikTextField;
